<template>
  <div class="columns is-mobile">
    <a
      v-if="downloadStatus === 'available'"
      class="column left-icon-col active"
      @click="initiateDownload()"
    >
      <IconDownload />
      {{ $gettext('Download') }}
    </a>
    <div
      v-else
      class="column left-icon-col disabled"
    >
      <template v-if="downloadStatus === 'disabled'">
        <IconDownload />
        {{ $gettext("Complete") }}
      </template>
      <template v-else-if="downloadStatus === 'downloading'">
        <IconSpinner />
        {{ $gettext("Downloading") }}
      </template>
      <template v-else-if="downloadStatus === 'complete'">
        <IconCheck />
        {{ $gettext("Complete") }}
      </template>
    </div>
    <div class="column data-col">
      <span class="item-label">
        {{ forDischargedChildren ? $gettext("Discharged children: ") : $gettext("Current children: ") }}
      </span>
      <IconSpinner v-if="childrenCount === null" />
      <span
        v-else
        class="muted"
      >
        {{ childrenCount }}
      </span>
      <PieChartProgressIndicator
        v-if="downloadStatus === 'downloading' && childrenCount"
        :percent-complete="childrenDownloadPercentComplete"
      />
      <ul class="list">
        <li v-if="site.growthEnabled">
          {{ $gettext("Growth assessments:") }}
          <IconSpinner v-if="growthCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ growthCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && growthCount"
            :percent-complete="growthDownloadPercentComplete"
          />
        </li>
        <li v-if="site.isChildMealtimeBestPracticeAssessmentEnabled">
          {{ $gettext("Best practice assessments:") }}
          <IconSpinner v-if="bestPracticesCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ bestPracticesCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && bestPracticesCount"
            :percent-complete="bestPracticeDownloadPercentComplete"
          />
        </li>
        <li v-if="site.isFeedingScreeningEnabled">
          {{ $gettext("Feeding Screenings:") }}
          <IconSpinner v-if="feedingScreeningCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ feedingScreeningCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && feedingScreeningCount"
            :percent-complete="feedingScreeningDownloadPercentComplete"
          />
        </li>
        <li v-if="site.isFeedingScreeningEnabled">
          {{ $gettext("Feeding Observations:") }}
          <IconSpinner v-if="feedingObservationCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ feedingObservationCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && feedingObservationCount"
            :percent-complete="feedingObservationDownloadPercentComplete"
          />
        </li>
        <li v-if="site.isFeedingScreeningEnabled">
          {{ $gettext("Positioning Observations:") }}
          <IconSpinner v-if="positioningObservationCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ positioningObservationCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && positioningObservationCount"
            :percent-complete="positioningObservationDownloadPercentComplete"
          />
        </li>
        <li v-if="site.isFeedingScreeningEnabled">
          {{ $gettext("Cup/Spoon Observations:") }}
          <IconSpinner v-if="cupOrSpoonObservationCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ cupOrSpoonObservationCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && cupOrSpoonObservationCount"
            :percent-complete="cupOrSpoonObservationDownloadPercentComplete"
          />
        </li>
        <li v-if="site.isFeedingScreeningEnabled">
          {{ $gettext("Self-Feeding Observations:") }}
          <IconSpinner v-if="selfFeedingObservationCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ selfFeedingObservationCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && selfFeedingObservationCount"
            :percent-complete="selfFeedingObservationDownloadPercentComplete"
          />
        </li>
        <li v-if="site.anemiaEnabled">
          {{ $gettext("Anemia assessments:") }}
          <IconSpinner v-if="anemiaCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ anemiaCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && anemiaCount"
            :percent-complete="anemiaDownloadPercentComplete"
          />
        </li>
        <li v-if="site.earlyidEnabled">
          {{ $gettext("Developmental screenings:") }}
          <IconSpinner v-if="developmentalScreeningCount === null" />
          <span
            v-else
            class="muted"
          >
            {{ developmentalScreeningCount }}
          </span>
          <PieChartProgressIndicator
            v-if="downloadStatus === 'downloading' && developmentalScreeningCount"
            :percent-complete="developmentalScreeningDownloadPercentComplete"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, Ref, watchEffect } from "vue"

  import {
    getPendingChildrenForSite,
    getPendingGrowthAssessmentsForSite,
    getPendingBestPracticeAssessmentsForSite,
    getPendingFeedingScreeningsForSite,
    getPendingFeedingObservationsForSite,
    getPendingPositioningObservationsForSite,
    getPendingCupOrSpoonObservationsForSite,
    getPendingSelfFeedingObservationsForSite,
    getPendingAnemiaAssessmentsForSite,
    getPendingDevelopmentalScreeningsForSite,
    processPaginatedChildrenForSite,
    processPaginatedGrowthAssessmentsForSite,
    processPaginatedBestPracticeAssessmentsForSite,
    processPaginatedFeedingScreeningsForSite,
    processPaginatedFeedingObservationsForSite,
    processPaginatedPositioningObservationsForSite,
    processPaginatedCupOrSpoonObservationsForSite,
    processPaginatedSelfFeedingObservationsForSite,
    processPaginatedAnemiaAssessmentsForSite,
    processPaginatedDevelopmentalScreeningsForSite,
  } from "@/services/Site"
  import { ISite } from "@/db"
  import { TaskQueuer } from "@/utils/Utilities"

  import IconSpinner from "@/components/svg/IconSpinner.vue"
  import PieChartProgressIndicator from "@/components/svg/PieChartProgressIndicator.vue"
  import IconDownload from "@/components/svg/IconDownload.vue"
  import IconCheck from "@/components/svg/IconCheckCircled.vue"

  interface Props {
    site: ISite,
    taskQueuer: TaskQueuer,
    getCounts: boolean,
    forDischargedChildren?: boolean,
  }

  const {
    site,
    taskQueuer,
    getCounts,
    forDischargedChildren,
  } = defineProps<Props>()
  const childrenCount = ref(null)
  const growthCount = ref(null)
  const bestPracticesCount = ref(null)
  const feedingScreeningCount = ref(null)
  const feedingObservationCount = ref(null)
  const positioningObservationCount = ref(null)
  const cupOrSpoonObservationCount = ref(null)
  const selfFeedingObservationCount = ref(null)
  const anemiaCount = ref(null)
  const developmentalScreeningCount = ref(null)
  const downloadStatus = ref("disabled")
  const childrenDownloadPercentComplete = ref(0)
  const growthDownloadPercentComplete = ref(0)
  const bestPracticeDownloadPercentComplete = ref(0)
  const feedingScreeningDownloadPercentComplete = ref(0)
  const feedingObservationDownloadPercentComplete = ref(0)
  const positioningObservationDownloadPercentComplete = ref(0)
  const cupOrSpoonObservationDownloadPercentComplete = ref(0)
  const selfFeedingObservationDownloadPercentComplete = ref(0)
  const anemiaDownloadPercentComplete = ref(0)
  const developmentalScreeningDownloadPercentComplete = ref(0)

  function initiateDownload() {
    downloadStatus.value = "downloading"
    taskQueuer.push(performDownload, true)
  }

  async function performDownload() {
    const urlParams = { discharged: forDischargedChildren }
    // Download children first, since we need to be able to associate assessments with them.
    if (childrenCount.value) {
      await processPaginatedChildrenForSite(site, urlParams, { percentComplete: childrenDownloadPercentComplete, total: childrenCount.value })
    }
    const downloadPromises = []
    if (site.growthEnabled && growthCount.value) {
      downloadPromises.push(
        processPaginatedGrowthAssessmentsForSite(
          site,
          urlParams,
          { percentComplete: growthDownloadPercentComplete, total: growthCount.value }
        )
      )
    }
    if (site.isChildMealtimeBestPracticeAssessmentEnabled && bestPracticesCount.value) {
      downloadPromises.push(
        processPaginatedBestPracticeAssessmentsForSite(
          site,
          urlParams,
          { percentComplete: bestPracticeDownloadPercentComplete, total: bestPracticesCount.value }
        )
      )
    }
    if (site.isFeedingScreeningEnabled && feedingScreeningCount.value) {
      downloadPromises.push(
        processPaginatedFeedingScreeningsForSite(
          site,
          urlParams,
          { percentComplete: feedingScreeningDownloadPercentComplete, total: feedingScreeningCount.value }
        )
      )
    }
    if (site.isFeedingObservationEnabled && feedingObservationCount.value) {
      downloadPromises.push(
        processPaginatedFeedingObservationsForSite(
          site,
          urlParams,
          { percentComplete: feedingObservationDownloadPercentComplete, total: feedingObservationCount.value }
        )
      )
    }
    if (site.anemiaEnabled && anemiaCount.value) {
      downloadPromises.push(
        processPaginatedAnemiaAssessmentsForSite(
          site,
          urlParams,
          { percentComplete: anemiaDownloadPercentComplete, total: anemiaCount.value }
        )
      )
    }
    if (site.earlyidEnabled && developmentalScreeningCount.value) {
      downloadPromises.push(
        processPaginatedDevelopmentalScreeningsForSite(
          site,
          urlParams,
          { percentComplete: developmentalScreeningDownloadPercentComplete, total: developmentalScreeningCount.value }
        )
      )
    }
    if (downloadPromises.length) {
      await Promise.all(downloadPromises)
    }
    // Need a second pass for processing the child observation types, since they rely
    // on having the parent observations being fully processed first.
    if (site.isFeedingObservationEnabled) {
      const observationPromises = []
      if (positioningObservationCount.value) {
        observationPromises.push(
          processPaginatedPositioningObservationsForSite(
            site,
            urlParams,
            { percentComplete: positioningObservationDownloadPercentComplete, total: positioningObservationCount.value }
          )
        )
      }
      if (cupOrSpoonObservationCount.value) {
        observationPromises.push(
          processPaginatedCupOrSpoonObservationsForSite(
            site,
            urlParams,
            { percentComplete: cupOrSpoonObservationDownloadPercentComplete, total: cupOrSpoonObservationCount.value }
          )
        )
      }
      if (selfFeedingObservationCount.value) {
        observationPromises.push(
          processPaginatedSelfFeedingObservationsForSite(
            site,
            urlParams,
            { percentComplete: selfFeedingObservationDownloadPercentComplete, total: selfFeedingObservationCount.value }
          )
        )
      }
      if (downloadPromises.length) {
        await Promise.all(observationPromises)
      }
    }
    downloadStatus.value = "complete"
  }

  async function getData() {
    // Run a series of async functions simultaneously to tally the number of items
    // available for download. When they all resolve, determine if they add up to
    // a positive number (indicating that we enable the option to download them).
    const args = { count: true, discharged: forDischargedChildren }

    async function promiseGetter(ref_: Ref<any>, func: Function) {
      const value = await func(site, args)
      ref_.value = value
      return value
    }

    const getterFunctions = [promiseGetter(childrenCount, getPendingChildrenForSite)]
    if (site.growthEnabled) {
      getterFunctions.push(promiseGetter(growthCount, getPendingGrowthAssessmentsForSite))
    }
    if (site.isChildMealtimeBestPracticeAssessmentEnabled) {
      getterFunctions.push(promiseGetter(bestPracticesCount, getPendingBestPracticeAssessmentsForSite))
    }
    if (site.isFeedingScreeningEnabled) {
      getterFunctions.push(promiseGetter(feedingScreeningCount, getPendingFeedingScreeningsForSite))
      getterFunctions.push(promiseGetter(feedingObservationCount, getPendingFeedingObservationsForSite))
      getterFunctions.push(promiseGetter(positioningObservationCount, getPendingPositioningObservationsForSite))
      getterFunctions.push(promiseGetter(cupOrSpoonObservationCount, getPendingCupOrSpoonObservationsForSite))
      getterFunctions.push(promiseGetter(selfFeedingObservationCount, getPendingSelfFeedingObservationsForSite))
    }
    if (site.anemiaEnabled) {
      getterFunctions.push(promiseGetter(anemiaCount, getPendingAnemiaAssessmentsForSite))
    }
    if (site.earlyidEnabled) {
      getterFunctions.push(promiseGetter(developmentalScreeningCount, getPendingDevelopmentalScreeningsForSite))
    }
    await Promise.all(getterFunctions).then((counts) => {
      // counts will be the number of pending items that each of the getterFuncs
      // returned. If counts sums to 0, that means there is no offline prep
      // to be done for this site/discharged status.
      downloadStatus.value = counts.reduce((a,b)=>a+b) ? "available" : "disabled"
    })
  }

  watchEffect(() => {
    if (getCounts) {
      taskQueuer.push(getData)
    }
  })

</script>
